import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [items, setItems] = useState([]); // Store fetched items
  const [searchTerm, setSearchTerm] = useState(''); // Search filter
  const [newItem, setNewItem] = useState({
    name: '',
    description: '',
    price: '',
    stock: '',
    category: '',
  }); // State for the new item input

  // Fetch items from the backend on load
  useEffect(() => {
    fetch('http://localhost:5000/items')
        .then((response) => response.json())
        .then((data) => setItems(data))
        .catch((error) => console.error('Error fetching items:', error));
  }, []);

  // Filter items based on search term (by name or ID)
  const filteredItems = items.filter(
      (item) =>
          item.ItemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.ItemID.toString().includes(searchTerm)
  );

  // Handle input changes for the new item form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem({ ...newItem, [name]: value });
  };

  // Handle form submission to add a new item
  const handleAddItem = (e) => {
    e.preventDefault();

    fetch('http://localhost:5000/items', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem),
    })
        .then((response) => response.json())
        .then((data) => {
          setItems([...items, data]); // Update the UI with the new item
          setNewItem({ name: '', description: '', price: '', stock: '', category: '' }); // Reset the form
        })
        .catch((error) => console.error('Error adding item:', error));
  };

  return (
      <div className="App">
        <header className="App-header">
          {/* Form to add a new item */}
          <form onSubmit={handleAddItem} className="item-form">
            <h2>Add a New Item</h2>
            <input
                type="text"
                name="name"
                placeholder="Item Name"
                value={newItem.name}
                onChange={handleInputChange}
                required
            />
            <input
                type="text"
                name="description"
                placeholder="Description"
                value={newItem.description}
                onChange={handleInputChange}
                required
            />
            <input
                type="number"
                name="price"
                placeholder="Price"
                value={newItem.price}
                onChange={handleInputChange}
                required
            />
            <input
                type="number"
                name="stock"
                placeholder="Stock"
                value={newItem.stock}
                onChange={handleInputChange}
                required
            />
            <input
                type="text"
                name="category"
                placeholder="Category"
                value={newItem.category}
                onChange={handleInputChange}
                required
            />
            <button type="submit">Add Item</button>
          </form>

          {/* Search Bar */}
          <h1>Search Item</h1>
          <input
              type="text"
              placeholder="Search by Name or ID"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
          />

          {/* Only display the search result section if searchTerm is not empty */}
          {searchTerm && (
              <>
                <h2>Search Results</h2>
                {filteredItems.length === 0 ? (
                    <p>No items found</p>
                ) : (
                    <ul className="item-list">
                      {filteredItems.map((item) => (
                          <li key={item.ItemID}>
                            <strong>{item.ItemName}</strong> - {item.Description} (${item.Price}) - Stock: {item.Stock}
                          </li>
                      ))}
                    </ul>
                )}
              </>
          )}
        </header>
      </div>
  );
}

export default App;
